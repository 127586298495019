.chat-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

}

.message-list{
    max-height: 90%;
}

.message-input{
    position: sticky;
    bottom: 20px;
}

.refresh-btn{
    position:sticky;
    top:65px;
    width:75px;
    z-index: 2;
    align-self: flex-end;
    margin-left: auto;
}