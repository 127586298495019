@import "styles/main";

$theme-colors: (
  primary: #866ec7,
  warning: #f8b739,
  success: green,
  dark: #091f36,
  danger: red,
  info: purple,
  secondary: #9eb4e4,
  first: #0090d9,
  second: #0aa699,
  third: #f35958,
  fourth: #735f87,
  unallocate: whitesmoke,
  allocate: orange,
);

@import "node_modules/bootstrap/scss/bootstrap.scss";

// /* Override ag grid theme */
// .ag-header-viewport {
//   font-family: "Encode Sans SC", sans-serif !important;
// }


body {
  margin: var(--header-height) 0 0 0;
  padding: 0 1rem;
  font-size: var(--normal-font-size);
  transition: 0.5s;
  background-color: #f5f5f5;
  font-size: 15px !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c0bcbc;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5a5555;
}

.modal-body {
  background-color: #f5f5f5;
}
html {
  font-size: 80%;
}

.container {
  @include flex(column, center, center);
}

.logincard {
  margin-left: 40%;
  margin-top: 15%;
  background-color: #f5f5f5;
  margin-right: 25%;
  width: 25%;
}

.loginImage {
  background-image: url("static/images/home.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  min-height: 100%;
  min-width: 1024px;

  /* Set up proportionate scaling */
  height: auto;
  top: 0;
  left: 0;

  /* Set up positioning */
  position: fixed;
}
@media screen and (max-width: 1024px) {
  /* Specific to this particular image */
  .loginImage {
    left: 50%;
    margin-left: -550px; /* 50% */
  }
}

/* utilities */
.no-padding {
  padding: 0px !important;
}

.pointer {
  cursor: pointer;
}

.card {
  box-shadow: 0 0 10px 0 rgba(100, 100, 100, 0.26);
}

.req {
  color: red;
}

.flex-gap > div:not(:first-of-type) {
  margin-top: 10px;
  margin-left: 0px;
}

/* sidebar */
:root {
  --header-height: 50px;
  --nav-width: 50px;
  --normal-font-size: 1rem;
  --z-fixed: 100;
}

.nav_name {
  margin-left: 1px;
}

.nav-link.active {
  color: #696969;
}

*,
::before,
::after {
  box-sizing: border-box;
}

a {
  text-decoration: none !important;
}

.header {
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: $color-Navbar;
  box-shadow: 0 0 10px 0 rgba(15, 15, 15, 0.26);
  z-index: var(--z-fixed);
  transition: 0.5s;
}

.header_toggle {
  color: $color-sidebar;
  font-size: 1.5rem;
  cursor: pointer;
  margin-left: -20px;
}

.header_img {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
}

.header_img img {
  // width: 40px;
  align-content: flex-end;
}

.setting-list {
  list-style-type: none;
}

.l-navbar {
  position: fixed;
  top: 0;
  left: -30%;
  width: var(--nav-width);
  height: 100vh;
  background-color: $color-sidebar;
  box-shadow: 0 0 10px 0 #f5f5f5;
  padding: 0.5rem 1rem 0 0;
  transition: 0.5s;
  z-index: var(--z-fixed);
}

.setting-link {
  color: $color-white;
  margin-bottom: 1rem;
  transition: 0.3s;
}

.filter-text-box {
  width: 50%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 20px;
  margin-left: 5px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $color-primary-light;
}

input:focus + .slider {
  box-shadow: 0 0 1px $color-primary-light;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  overflow-y: auto;
  width: inherit;
}

.nav_logo,
.nav_link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
}

.nav_logo {
  margin-bottom: 2rem;
}

.nav_logo-icon {
  font-size: 1.25rem;
  color: $color-white;
}

.nav_logo-name {
  color: #fff;
  font-weight: 700;
}

.nav_link {
  color: #f5f5f5;
  margin-bottom: 0.5rem;
  transition: 0.3s;
}

.nav_link:hover {
  color: #696969;
}

.nav_icon {
  font-size: 1.25rem;
}

.show-sidebar {
  left: 0;
}

.body-pd {
  padding-left: calc(var(--nav-width) + 1rem);
}

.active {
  color: #696969;
}

.nav_link:active {
  color: #696969;
}

.active::before {
  content: "";
  position: absolute;
  left: 0;
  width: 2px;
  height: 32px;
  background-color: $color-white;
}

.height-100 {
  height: 100vh;
}

@media screen and (min-width: 768px) {
  body {
    margin: calc(var(--header-height) + 1rem) 0 0 0;
    padding-left: calc(var(--nav-width) + 2rem);
  }

  .header {
    height: calc(var(--header-height) + 1rem);
    padding: 0 2rem 0 calc(var(--nav-width) + 2rem);
  }

  .header_img {
    width: 20px;
    height: 20px;
  }

  .header_img img {
    // width: 45px;
  }

  .l-navbar {
    left: 0;
    // padding: 1rem 1rem 0 0;
  }

  .show-sidebar {
    width: calc(var(--nav-width) + 156px);
  }

  .flex-gap > div:not(:first-of-type) {
    margin-left: 10px;
    margin-top: 0px;
  }

  .body-pd {
    padding-left: calc(var(--nav-width) + 188px);
  }
}

.calendarDiv {
  height: 110px !important;
  margin-top: 5px;
  font-size: small;
  padding: 2%;
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: whitesmoke !important;
  box-shadow: 0 0 10px 0 rgba(15, 15, 15, 0.26);
}

.allocatedDiv {
  height: 110px !important;
  margin-top: 5px;
  font-size: small;
  padding: 2%;
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: orange !important;
  box-shadow: 0 0 10px 0 rgba(15, 15, 15, 0.26);
}

.blur {
  opacity: 0.5;
}

.confirmedDiv {
  height: 110px !important;
  margin-top: 5px;
  padding: 2%;
  font-size: small;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  background-color: green !important;
  box-shadow: 0 0 10px 0 rgba(15, 15, 15, 0.26);
}

.cancelDiv {
  height: 110px !important;
  font-size: small;
  margin-top: 5px;
  padding: 2%;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  background-color: red !important;
  box-shadow: 0 0 10px 0 rgba(15, 15, 15, 0.26);
}

.activeCard {
  height: 110px !important;
  margin-top: 5px;
  font-size: small;
  padding: 2%;
  text-align: center;
  color: black;
  display: flex;
  flex-direction: column;

  box-shadow: 0 0 10px 0 rgba(15, 15, 15, 0.26);
  border: 2px dashed rgba(15, 15, 15, 0.26);
}

.grid-title {
  padding: 1%;
  background-color: $color-primary-light;
}

/*Forgot-password*/
.forgot-password {
  max-width: 50%;
  margin-left: 20%;
  margin-top: 10%;
}

/* sticky header */
.shift-header {
  position: sticky;
  top: 64px;
  z-index: 2;
  background-color: #f5f5f5;
  .search-box{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .form-control {
      margin-left: 10px;
    }
  }
}

.table-sticky-header {
  position: sticky;
  top: 201px;
  z-index: 2;
  background-color: #f5f5f5;
}

.btn-toggle-nav {
  margin-left: 10%;
}

.login-logo {
  border-radius: 10px;
}

.view-site-button {
  height: 100%;
}

.ag-theme-quartz {
  font-size: 15px !important;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: 600;

}