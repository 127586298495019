.check-calls {
    cursor: pointer;
    border: 1px solid black;
    &.checked {
        background-color: #0aa699;
        color: #ffff;
    }
    &.missed {
        background-color:#f35958;
        color: #ffff;
    }
}